import React, { useState, useEffect } from "react";
import InputField from "components/fields/InputField";
import { Link, useNavigate, useParams } from "react-router-dom"; // Import useParams to get URL params
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { checkOrganisationExists, loginUser } from "services/apiService";

// Define the expected shape of the API response
interface OrgResponse {
  exists: boolean;
}

const Organisation: React.FC = () => {
  const { orgSlug } = useParams(); // Retrieve orgSlug from the URL
  const [orgValue, setOrgValue] = useState<string>(orgSlug || ""); // Set the initial value to orgSlug
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showLoginFields, setShowLoginFields] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Update orgValue if orgSlug changes
    if (orgSlug) {
      setOrgValue(orgSlug);
    }
  }, [orgSlug]);

  // Function to check if the organization exists via API call
  const checkOrganisation = async () => {
    setIsLoading(true); // Start loading state
    try {
      const result: OrgResponse = await checkOrganisationExists(orgValue); // Call the API service
      if (result.exists) {
        toast.success("Organization exists. Please enter your login details.");
        setShowLoginFields(true); // Show email/password fields if org exists
      } else {
        toast.error("This organization does not exist");
        setShowLoginFields(false); // Hide login fields if org doesn't exist
      }
    } catch (error) {
      toast.error("Error checking organization. Please try again later.");
    } finally {
      setIsLoading(false); // End loading state
    }
  };

  // Function to handle user login
  const handleLogin = async () => {
    try {
      // Call login API
      await loginUser(email, password);
      toast.success("Login successful! Redirecting...");
      // Redirect to dashboard and reload the page
      navigate('/dashboard/default');
      window.location.reload();  // Refresh the page

    } catch (error) {
      toast.error("Login failed. Please check your credentials.");
    }
  };

  return (
    <div className="flex h-screen items-center justify-center bg-white dark:bg-darkPrimary px-4 md:px-0">
      {/* Organisation Name/URL section */}
      <div className="w-full max-w-md p-6 bg-white shadow-md rounded-lg">
        <h4 className="mb-4 text-3xl font-bold text-navy-700 dark:text-white">
          Organisation Slug
        </h4>
        <p className="mb-6 text-base text-gray-800 dark:text-gray-300">
          Enter your organisation Slug to login.
        </p>

        {/* Organisation Slug Input */}
        <InputField
          variant="auth"
          extra="mb-4"
          label="Organisation*"
          placeholder="My Organisation"
          id="organisation"
          type="text"
          value={orgValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOrgValue(e.target.value)} // Handle input change
        />

        {/* Submit Button */}
        {!showLoginFields && (
          <button
            className={`w-full rounded-xl py-3 text-base font-medium text-white transition duration-200 ${
              isLoading
                ? "bg-brand-400 cursor-not-allowed"
                : "bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            }`}
            onClick={checkOrganisation} // Call function on button click
            disabled={isLoading} // Disable the button if loading
          >
            {isLoading ? "Checking..." : "Submit"}
          </button>
        )}

        {/* Conditional rendering of email/password input fields */}
        {showLoginFields && (
          <div className="mt-6">
            <InputField
              variant="auth"
              extra="mb-4"
              label="Email*"
              placeholder="Your Email"
              id="email"
              type="email"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} // Handle input change
            />
            <InputField
              variant="auth"
              extra="mb-4"
              label="Password*"
              placeholder="Your Password"
              id="password"
              type="password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)} // Handle input change
            />
            <button
              className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={handleLogin} // Call login function on click
            >
              Sign In
            </button>
          </div>
        )}

        <div className="mt-4 text-center">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            No Need to reset?
          </span>
          <Link
            to="/signin"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Sign In
          </Link>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Organisation;
