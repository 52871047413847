import { Routes, Route, Navigate, useParams } from "react-router-dom";
import AdminLayout from "layouts/dashboard";
import Register from "views/auth/Register";
import SignIn from "views/auth/SignIn";
import ForgotPassword from "views/auth/ForgotPassword";
import ResetPassword from "views/auth/ResetPassword";
import Organisation from "views/auth/Organisation";
import InviteHandler from 'views/auth/InviteHandler';


const useAuth = () => {
  const isAuthenticated = !!localStorage.getItem("accessToken");
  return isAuthenticated;
};

const OrganisationRedirect = () => {
  const { orgSlug } = useParams();
  return <Navigate to={`/orginisation/${orgSlug}`} replace />;
};

const App = () => {
  const isAuthenticated = useAuth();

  return (
    <Routes>
      {/* Public routes */}
      {!isAuthenticated ? (
        <>
          <Route path="invite/:token" element={<InviteHandler />} />
          <Route path="register/" element={<Register />} />
          <Route path="signin/" element={<SignIn />} />

          {/* Route for forgot and Reset Password with token */}
          <Route path="forgot-password/" element={<ForgotPassword />} />
          <Route path="reset-password/:token" element={<ResetPassword />} />
          
          <Route path="orginisation/" element={<Organisation />} />
          
          {/* Dynamic URL Route for Organisation and Organisation with orgSlug as a parameter*/}
          <Route path=":orgSlug" element={<OrganisationRedirect />} />
          <Route path="orginisation/:orgSlug" element={<Organisation />} />

          {/* Redirect all other paths to Sign In */}
          <Route path="*" element={<Navigate to="/signin/" replace />} />
        </>
      ) : (
        <>
          {/* Protected routes */}
          <Route path="dashboard/*" element={<AdminLayout />} />
          {/* Default redirect for authenticated users */}
          <Route path="/" element={<Navigate to="/dashboard/" replace />} />
          {/* Catch-all route to dashboard */}
          <Route path="*" element={<Navigate to="/dashboard/" replace />} />
        </>
      )}
    </Routes>
  );
};

export default App;
