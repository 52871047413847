const Footer = () => {
  return (
    <div
      className="flex w-full flex-col items-center justify-center px-4 py-3 lg:px-8 xl:flex-row"
      style={{ backgroundColor: "#06283D" }}
    >
      <p className="text-left text-sm text-white md:text-base">
        ©2024 DataActs. All Rights Reserved.
      </p>
    </div>
  );
};

export default Footer;
