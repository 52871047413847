import React, { useEffect, useState } from 'react';
import { MdCheckCircle, MdPriceCheck } from 'react-icons/md';
import Card from 'components/card';
import { getPricingPlans, createSubscription, verifySubscription, fetchProfile } from 'services/apiService';
import axios from 'axios';
import { loadRazorpay } from 'services/razorpayService';
import { toast } from 'react-toastify';


const PricingCard = () => {
  const [plans, setPlans] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const [interval, setInterval] = useState<string>('monthly');
  const [currency, setCurrency] = useState<string>('USD');
  const [userProfile, setUserProfile] = useState<any>(null);
  const [subscribingPlan, setSubscribingPlan] = useState<string | null>(null);

  // Fetch user profile
  useEffect(() => {
    fetchProfile()
      .then(profileData => {
        setUserProfile(profileData);
      })
      .catch(error => {
        console.error('Error fetching user profile:', error);
      });
  }, []);

  // Fetch the user's location based on their IP to set currency
  useEffect(() => {
    axios.get('https://ipapi.co/json/')
      .then(response => {
        const country = response.data.country;
        if (country === 'IN') {
          setCurrency('INR');
        } else {
          setCurrency('USD');
        }
      })
      .catch(error => {
        console.error("Error fetching the user's location", error);
      });
  }, []);

  // Fetch pricing data from the API
  useEffect(() => {
    getPricingPlans()
      .then(data => {
        setPlans(data.all_pricing);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  // Handle Razorpay Payment
  const handleSubscription = async (plan: any) => {
    setSubscribingPlan(plan.razorpay_plan_id);
    try {
      const subscriptionData = await createSubscription(plan.razorpay_plan_id);
      const razorpayKey = 'rzp_test_Vokfjd9ISf7V6S';

      loadRazorpay(() => {
        const options = {
          key: razorpayKey,
          subscription_id: subscriptionData.subscription_id,
          name: 'DataActs BI Tool',
          description: 'Activated the ' + plan.name + ' Plan',
          currency: currency,
          handler: async function (response: any) {
            toast.success(`Subscription successfully activated.`);
            try {
              await verifySubscription(
                response.razorpay_subscription_id,
                response.razorpay_payment_id,
                response.razorpay_signature
              );
              window.location.href = '/dashboard/subscription';
            } catch (error) {
              alert('Payment verification failed. Please contact support.');
            }
          },
          prefill: {
            name: userProfile?.full_name || 'Gaurav Kumar',
            email: userProfile?.email || 'gaurav.kumar@example.com',
            contact: userProfile?.contact_number || '9000090000',
          },
          theme: {
            color: '#4F61FF',
          },
          notes: {
            address: 'Razorpay Corporate Office',
          },
        };

        const razorpayInstance = new (window as any).Razorpay(options);
        razorpayInstance.open();
      });
    } catch (error) {
      console.error('Error initializing Razorpay:', error);
      alert('Subscription creation failed. Please try again.');
    } finally {
      setSubscribingPlan(null); // Reset subscribing state
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching pricing data.</p>;

  const filteredPlans = plans.filter(plan => plan.interval === interval);

  return (
    <>
      <div className="flex text-center mb-4">
        <button
          onClick={() => setInterval('monthly')}
          className={`py-2 px-4 rounded-lg ${interval === 'monthly' ? 'bg-brand-500 text-white' : 'bg-gray-200 text-gray-800'} transition duration-200`}
        >
          Monthly
        </button>
        <button
          onClick={() => setInterval('yearly')}
          className={`py-2 px-4 rounded-lg ${interval === 'yearly' ? 'bg-brand-500 text-white' : 'bg-gray-200 text-gray-800'} transition duration-200 ml-2`}
        >
          Yearly
        </button>
      </div>
      <br />

      {filteredPlans.map((plan, index) => (
        <Card key={index} extra="!p-[20px] text-center">
          <div className="flex justify-between">
            <div className="text-left">
              <h3 className="text-2xl font-bold">{plan.name}</h3>
              <p className="text-sm text-gray-600">{plan.tag_line_for_plan}</p>
            </div>
            <button className="flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100">
              <MdPriceCheck className="text-green-500" />
            </button>
          </div>

          <div className="mt-[20px]">
            <p className="text-5xl font-bold">
              {currency === 'USD' ? `$${plan.amount_in_usd}` : `₹${plan.amount_in_inr}`}
              <span className="text-sm font-normal text-gray-600">/{plan.interval}</span>
            </p>
          </div>

          <div className="mt-[20px] text-left">
            {plan.descriptions.map((desc: string, i: number) => (
              <div key={i} className="flex items-center gap-2">
                <MdCheckCircle className="text-green-500" />
                <p className="text-sm text-gray-600">{desc}</p>
              </div>
            ))}
          </div>

          <button
            onClick={() => handleSubscription(plan)}
            className="mt-6 w-full rounded-lg bg-brand-500 py-2 text-white hover:bg-brand-600"
            disabled={subscribingPlan === plan.razorpay_plan_id} // Disable button if plan is being subscribed
          >
            {subscribingPlan === plan.razorpay_plan_id ? (
              <svg 
                className="animate-spin h-5 w-5 mx-auto text-white" 
                xmlns="http://www.w3.org/2000/svg" 
                fill="none" 
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12" 
                  cy="12" 
                  r="10" 
                  stroke="currentColor" 
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor" 
                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                ></path>
              </svg>
            ) : (
              'Subscribe Now'
            )}
          </button>
        </Card>
      ))}
    </>
  );
};

export default PricingCard;
