import InviteTeamForm from "views/dashboard/invite/components/InviteTeamForm";

const InviteDashboard = () => {
  return (
    <div>
      <div className="mt-5 grid grid-cols-12 gap-5 md:grid-cols-12">
        <div className="col-span-12 md:col-span-6">
            <InviteTeamForm />
        </div>
        </div>
    </div>
  );
};

export default InviteDashboard;
