import React, { useEffect, useState } from "react";
import General, { Profile } from "./components/General";
import Information from "./components/Information";
import { fetchProfile, updateProfile } from "services/apiService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Index: React.FC = () => {
  const [profile, setProfile] = useState<Profile | null>(null); // Initialize as null
  const [loading, setLoading] = useState<boolean>(true); // Loading state

  useEffect(() => {
    const getProfileData = async () => {
      setLoading(true);
      try {
        const profileData = await fetchProfile();
        setProfile(profileData);
      } catch (error) {
        console.error("Failed to fetch profile data", error);
      } finally {
        setLoading(false);
      }
    };

    getProfileData();
  }, []);

  const updateProfileData = async (updatedProfile: Profile) => {
    try {
      await updateProfile(updatedProfile);
      setProfile(updatedProfile);
      toast.success("Profile updated successfully!");
    } catch (error) {
      toast.error("Error updating profile.");
    }
  };

  // Check if loading or profile is not available
  if (loading) {
    return <div>Loading...</div>; // Loading state UI
  }

  if (!profile) {
    return <div>No profile data available.</div>; // Handle case when profile is not available
  }

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
        <Information profile={profile} />
        </div>
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">
        <General profile={profile} updateProfileData={updateProfileData} />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Index;