import React, { useState } from "react";
import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { Link, useNavigate } from "react-router-dom";
import LogoIcon from "components/LogoIcon/LogoIcon";
import { loginUser } from "services/apiService";
import { ToastContainer, toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function SignIn() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    try {
      await loginUser(email, password);
      toast.success("Successfully SignIn!");
      setTimeout(() => {
        window.location.reload();
      }, 1000);      
    } catch (err: any) {
      if (err.response) {
        const statusCode = err.response.status;
        const errorMessage = err.response;
        if (statusCode === 404) {
          toast.error("User not found with this email.");
        } else if (statusCode === 401) {
          toast.error("Entered password is incorrect.");
        } else {
          toast.error("Login failed. Please check your entered values and try again.");
        }
      } else {
        toast.error("Login failed. Please check your network connection and try again.");
      }
    }
  };

  return (
    <div className="flex h-screen items-center justify-center bg-white dark:bg-darkPrimary">
      <div className="w-full max-w-md p-6 bg-white shadow-md rounded-lg">
        <h4 className="mb-4 text-3xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-6 text-base text-gray-800 dark:text-gray-300">
          Enter your email and password to sign in!
        </p>
        
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-4"
          label="Email*"
          placeholder="mail@example.com"
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {/* Password with Show/Hide Icon */}
        <div className="relative">
          <InputField
            variant="auth"
            extra="mb-4"
            label="Password*"
            placeholder="Enter your password"
            id="password"
            type={showPassword ? "text" : "password"} // Toggle input type
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            className="absolute right-3 top-10 text-white text-gray-500"
            onClick={() => setShowPassword(!showPassword)} // Toggle visibility
          >
            {showPassword ? (
              <FaEyeSlash className="text-black !important" size={25} style={{ color: '#4F61FF' }}/>
            ) : (
              <FaEye className="text-black !important" size={25} style={{ color: '#4F61FF' }}/>
            )}
          </button>
        </div>

        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged in
            </p>
          </div>
          <Link
            to="/forgot-password/"
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Forgot Password?
          </Link>
        </div>

        <button
          onClick={handleSubmit}
          className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Sign In
        </button>

        <div className="mt-4 text-center">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <Link
            to="/register"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </Link>
          <br />
          <Link
            to="/orginisation/"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            I know my organization
          </Link>
        </div>
      </div>
      <LogoIcon className="mb-4" />
      <ToastContainer />
    </div>
  );
}
