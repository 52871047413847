import React, { useState, useEffect } from "react";
import InputField from "components/fields/InputField";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LogoIcon from "components/LogoIcon/LogoIcon";
import { registerUser } from "services/apiService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface FormData {
  fullname: string;
  email: string;
  password: string;
  confirmPassword: string;
  organization: string;
}

export default function Register() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [formData, setFormData] = useState<FormData>({
    fullname: '',
    email: '',
    password: '',
    confirmPassword: '',
    organization: '',
  });

  const [isChecked, setIsChecked] = useState<boolean>(false); // State for checkbox
  const [isOrgDisabled, setIsOrgDisabled] = useState<boolean>(false); // Disable org field if from URL

  // Extract organization from URL query params if present
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const organization = searchParams.get('organization');
    if (organization) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        organization,
      }));
      setIsOrgDisabled(true); // Disable the organization field
    }
  }, [location]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, checked } = e.target;
    if (id === "terms") {
      setIsChecked(checked);
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Basic validation
    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }

    if (!formData.fullname || !formData.email || !formData.password || !formData.organization) {
      toast.error('Please fill in all required fields.');
      return;
    }

    if (!isChecked) {
      toast.error('You must agree to the terms and conditions.');
      return;
    }

    try {
      await registerUser({
        fullname: formData.fullname,
        email: formData.email,
        password: formData.password,
        organization: formData.organization,
      });
      toast.success('Registration successful!');
      //  Wait for 2 seconds (2000 milliseconds) before redirecting 
      setTimeout(() => {
        navigate('/signin');
      }, 2000);
    } catch (err: any) {
      if (err && err.response) {
        toast.error(err.response.data.error || 'Registration failed. Please try again.');
      } else {
        toast.error('Registration failed. Please check your network connection and try again.');
      }
    }
  };

  return (
    <div className="flex h-screen items-center justify-center px-4 bg-white dark:bg-darkPrimary">
      <div className="w-full max-w-md flex-col items-center bg-white dark:bg-darkSecondary p-6 rounded-lg shadow-md">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Create an Account
        </h4>
        <p className="mb-6 text-base text-gray-800 dark:text-gray-300">
          Enter your details to create an account!
        </p>

        <form onSubmit={handleSubmit}>
          <InputField
            variant="auth"
            extra="mb-3"
            label="Full Name*"
            placeholder="John Doe"
            id="fullname"
            type="text"
            value={formData.fullname}
            onChange={handleChange}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="mail@example.com"
            id="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Organisation*"
            placeholder="My Organisation"
            id="organization"
            type="text"
            value={formData.organization}
            onChange={handleChange}
            disabled={isOrgDisabled} // Disable the field if organization is passed from URL
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Enter your password"
            id="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Confirm Password*"
            placeholder="Re-enter your password"
            id="confirmPassword"
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
          <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              id="terms"
              checked={isChecked}
              onChange={handleChange}
              className="form-checkbox h-4 w-4 text-brand-500"
            />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              I agree to the terms and conditions
            </p>
          </div>
          <button
            type="submit"
            className="w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Register
          </button>
        </form>
        <div className="mt-4 text-center">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            Already have an account?
          </span>
          <Link
            to="/signin"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Sign In
          </Link>
        </div>
      </div>
      <ToastContainer />
      <LogoIcon className="mb-4" />
    </div>
  );
}
